@charset "UTF-8";
/*
  Usado para comprobar el ritmo vertical
  Generalmente se aplica a la etiqueta html

  $line-height: separación entre las líneas (en píxeles)
  $offset: ajuste del comienzo de la primera línea (en píxeles)
  $line-color: color de las líneas horizontales
*/
/*
===========================================
==  Paleta de colores  ====================
===========================================
*/
/*
===========================================
==  breakpoints  ==========================
===========================================
*/
/*
===========================================
== fuentes ================================
===========================================
*/
/*
===========================================
==  márgenes  =============================
===========================================
*/
/*
===========================================
==  themes  ===============================
===========================================
*/
/*
===========================================
==  forms  ================================
===========================================
*/
.p-guide {
  background-color: #fff;
  padding-top: 2rem;
}

.p-guide ul {
  list-style: circle;
  padding-left: 2rem;
}

.p-guide ul li {
  margin-bottom: 0.5rem;
}

.p-guide__page-name {
  text-align: center;
  font-size: 1.5rem;
  text-transform: uppercase;
}

.p-guide__title {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  padding: 0.1875rem 0.375rem;
  margin-left: -5px;
  margin-right: -5px;
  background-color: #999;
  font-family: inherit;
  color: #fff;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.5;
}

@media (min-width: 576px) {
  .p-guide__title {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
}

@media (min-width: 768px) {
  .p-guide__title {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media (min-width: 992px) {
  .p-guide__title {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 1200px) {
  .p-guide__title {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.p-guide__subtitle {
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
  font-family: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5;
}
